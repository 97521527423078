<template>
  <div v-if='asset' class="edit">
    <div class="product-edit-form">
      <h3>{{ asset.internal_name }}</h3>
      ID: {{ asset.id }}

      <div class="difficulty-container">
        <label>Difficulty Levels</label>
        <p>
          These are used to map the asset difficulty levels to specific wording. (ie: Simple, Easy, Medium, Hard, Impossible)
        </p>
        <div class="difficulties" v-if="asset.difficulty_levels">
          <div class="level">
            <label>Level 1</label>
            <input type="text" v-model="asset.difficulty_levels[0].label" />
          </div>
          <div class="level">
            <label>Level 2</label>
            <input type="text" v-model="asset.difficulty_levels[1].label" />
          </div>
          <div class="level">
            <label>Level 3</label>
            <input type="text" v-model="asset.difficulty_levels[2].label" />
          </div>
          <div class="level">
            <label>Level 4</label>
            <input type="text" v-model="asset.difficulty_levels[3].label" />
          </div>
          <div class="level">
            <label>Level 5</label>
            <input type="text" v-model="asset.difficulty_levels[4].label" />
          </div>
        </div>
      </div>
    </div>

    <edit-footer>
      <template v-slot:left>
        <div class="left">
        </div>
      </template>
      <template v-slot:center>
        <div class="center">
          <div>
            <button
              v-if="!saving"
              class="hlt-button primary save-button"
              @click="save()"
            >
              Save Changes
            </button>
            <button
              v-else
              class="hlt-button primary disabled save-button"
            >
              <spinner /> Save Changes
            </button>
          </div>
        </div>
      </template>
      <template v-slot:right>
      </template>
    </edit-footer>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import railsRoutes from '../../../store/rails-routes'
import EditFooter from '../../assets/shared/page_components/EditFooter'
import Spinner from '../../assets/shared/Spinner'

export default {
  components: {
    EditFooter,
    Spinner
  },
  data () {
    return {
      saving: false
    }
  },
  computed: {
    ...mapGetters([
      'asset'
    ])
  },
  methods: {
    ...mapActions([
      'fetchProductById',
      'edit',
      'update'
    ]),
    ...mapMutations([
      'setAssetType'
    ]),
    save () {
      if (!this.saving) {
        this.saving = true
        if (this.asset.id) {
          this.update({ asset: this.asset, url: railsRoutes.api_path({ type: 'products' }) }).then(response => {
            this.edit(response.data.records[0])
            this.saving = false
            this.$notify({ title: 'Product Saved', type: 'success' })
          }).catch(error => {
            console.error('Error: ', error.response.data)
            this.saving = false
            this.$notify({ title: 'Error saving product', type: 'error' })
          })
        } else {
          // This should never happen
          this.saving = false
          this.$notify({ title: 'Error saving product', type: 'error' })
        }
      }
    }
  },
  mounted () {
    this.setAssetType({ assetType: 'product' })
    this.fetchProductById({ productId: this.$route.params.id }).then(response => {
      var record = response.data.records[0]
      this.edit(record)
    })

  }
}
</script>

<style lang="scss" scoped>
.edit {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.product-edit-form {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-left: 40px;
  label {
    font-weight: 700;
  }
  .difficulty-container {
    margin: 20px 0px;
    label {
      font-size: 1.5em;
    }
    p {
      color: $space-gray;
    }
  }
  .difficulties {
    display: flex;
    .level {
      width: 15%;
      margin-right: 20px;
      label {
        font-size: 1em;
      }
    }
  }
}
</style>