<template>
  <div class="draft-live-footer">
    <div class="footer-content">
      <slot name="left" />
      <slot name="center" />
      <slot name="right" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditFooter'
}
</script>

<style lang="scss">
.draft-live-footer {
  z-index: 999;
  bottom: 0;
  right: 0;
  position: sticky;
  background: $space-gray-10;

  .footer-content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: nowrap;

    .left {
      display: flex;
      justify-content: center;
      flex: 1;
      padding: 20px 0;

      .draft {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      .live {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

    }
    .center {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      padding: 20px 50px;
      flex: 4;
    }

    .right {
      border-left: 2px solid $space-gray;
      display: flex;
      justify-content: space-around;
      flex: 1;
      align-self: stretch;
      padding: 0;

      button.hlt-button {
        margin: 20px 0;
      }
    }

  }
}
</style>
