<template>
<nav-container>
    <template v-slot:headerName>
      <div class="title">
        Settings
      </div>
    </template>
    <template v-slot:listItems>
      <ul class="menu">
        <li
          v-if="currentUser.permissions['can_read_roles']"
          :class="assetClasses('roles')"
          class="nav-button roles"
        >
          <a :href="railsRoutes.cms_path({ type: 'roles' })">Roles</a>
        </li>
        <li
          v-if="currentUser.permissions['can_read_themes']"
          :class="assetClasses('themes')"
          class="nav-button themes"
        >
          <a :href="railsRoutes.cms_path({ type: 'themes' })">Themes</a>
        </li>
        <li
          v-if="currentUser.permissions['can_read_tags']"
          :class="assetClasses('tags')"
          class="nav-button tags-section"
        >
          <a :href="railsRoutes.cms_path({ type: 'tags' })">Tags</a>
        </li>
        <li
          v-if="currentUser.permissions.can_write_web_app_configs || currentUser.permissions.can_write_apps"
          :class="assetClasses('products')"
          class="nav-button tags-section"
        >
          <a :href="railsRoutes.cms_path({ type: 'products' })">Products</a>
        </li>
        <li
          v-if="currentUser.permissions.can_read_content_sources"
          :class="assetClasses('content-sources')"
          class="nav-button content-source-section"
        >
          <a :href="railsRoutes.cms_path({ type: 'content-sources' })">Content Sources</a>
        </li>
      </ul>
    </template>
  </nav-container>
</template>

<script>
import { mapGetters } from 'vuex'
import railsRoutes from '../../../store/rails-routes'
import NavContainer from '../../shared/NavContainer'
export default {
  components: {
    NavContainer
  },
  data () {
    return {
      railsRoutes: railsRoutes
    }
  },
  computed: {
    ...mapGetters([
      'currentUser'
    ])
  },
  methods: {
    assetClasses (type) {
      let active = false
      if (type == 'roles') {
        active = this.$router.isRoute('role')
      }
      if (type == 'themes') {
        active = this.$router.isRoute('theme')
      }
      if (type == 'tags') {
        active = this.$router.isRoute('tag')
      }
      if (type == 'content-sources') {
        active = this.$router.isRoute('content-source')
      }
      if (type == 'products') {
        active = this.$router.isRoute('product')
      }
      return {
        'nav-item': true,
        'top-active': active
      }
    }
  }
}
</script>
