<template>
  <div class="edit-container">
    <top-nav />
    <product-edit v-if="this.$route.name == 'product'" />
  </div>
</template>

<script>
import TopNav from '../shared/TopNav.vue'
import ProductEdit from '../products/Edit.vue'

export default {
  components: {
    TopNav,
    ProductEdit
  }
}
</script>

<style lang="scss" scoped>
.edit-container {
  position: relative;
  height: 100%;
}
</style>